<template>
  <div class="auto-graph-manage">
    <div class="title">
      <span>签名设置</span>
      <en-button style="height: 30px;font-size: 12px;" @click="addAutoGraph" iconSize="12" icon="add">添加</en-button>
    </div>
    <en-result type="NoData" v-show="autoGraphList.length === 0 && !loading"></en-result>
    <div class="auto-graph-container" v-loading="loading">
      <en-card
        class="auto-graph-card"
        v-for="(card, index) in autoGraphList"
        :key="card.id"
        type="big"
        :title="card.title"
        :data-info="card"
        icon-name="iconqianmingtubiao-youxiang"
        icon-color="#d69e79"
        edit-able="true"
        :menu-list="menuList(card, index)"
        @change="handlerCallback"
      >
        <template slot="description">
          <div v-html="card.content"></div>
        </template>
        <template slot="tag">
          <div>
            <span class="default-tag" v-if="card.isDefault === 1"></span>
            <span class="default-reply-tag" v-if="card.isReplyDefault === 1"></span>
          </div>
        </template>
      </en-card>
    </div>
    <preview-dialog ref="previewDialog"></preview-dialog>
    <create-auto-graph-dialog :dialog-type="dialogType" ref="autoGraphDialog" @submitForm="submitForm"></create-auto-graph-dialog>
  </div>
</template>

<script>
import { mailSettingService } from "@/api/mailBox/mailSetting";
import CreateAutoGraphDialog from "../../components/MailSetting/CreateAutoGraphDialog";
import PreviewDialog from "../../components/MailSetting/PreviewDialog";

export default {
  name: "AutoGraphSetting",
  components: {
    CreateAutoGraphDialog,
    PreviewDialog
  },
  data() {
    return {
      // 弹窗类型 添加or编辑
      dialogType: "add",
      autoGraphList: [],
      loading: false
    };
  },
  mounted() {
    this.getSignList();
  },
  methods: {
    addAutoGraph() {
      this.dialogType = "add";
      this.$nextTick(() => {
        this.$refs.autoGraphDialog.show();
      });
    },
    async submitForm(data) {
      const autoGraphDialogRef = this.$refs.autoGraphDialog;
      if (this.dialogType === "add") {
        await mailSettingService.addMailSign(data);
      }
      if (this.dialogType === "update") {
        await mailSettingService.updateMailSign(data);
      }
      autoGraphDialogRef.hide();
      this.getSignList();
    },
    async handlerCallback({ operation }, card) {
      // 查看全文
      if (operation === "preview") {
        this.$refs.previewDialog.show(card);
      }
      // 删除
      if (operation === "del") {
        const h = this.$createElement;
        this.$msgbox({
          title: "",
          closeOnClickModal: false,
          message: h("div", { style: { display: "flex", alignItems: "center", fontSize: "12px" } }, [
            h("en-icon", { attrs: { name: "tishi", color: "#F76B6A" } }),
            h("div", { style: { marginLeft: "10px" } }, [h("p", {}, "确定删除该签名？")])
          ]),
          confirmButtonClass: "mail-btn-error",
          callback: async(action) => {
            if (action === "confirm") {
              await mailSettingService.deleteMailSign({ id: card.id });
              this.autoGraphList = this.autoGraphList.filter((item) => item.id !== card.id);
              this.$message.success("删除成功");
            }
          }
        });
      }
      // 设置默认
      if (operation === "default" || operation === "defaultReply") {
        const params = {
          id: card.id
        };
        operation === "default" ? params.isDefault = 1 : params.isReplyDefault = 1;
        await mailSettingService.updateMailSign(params);
        this.autoGraphList.forEach((item) => {
          operation === "default" ? item.isDefault = 0 : item.isReplyDefault = 0;
        });
        operation === "default" ? card.isDefault = 1 : card.isReplyDefault = 1;
        this.$message.success(operation === "default" ? "设置默认新邮件签名成功" : "设置默认转发/回复签名成功");
      }
      // 编辑账号
      if (operation === "edit") {
        this.dialogType = "update";
        this.$nextTick(() => {
          this.$refs.autoGraphDialog.show(card);
        });
      }
    },
    menuList() {
      return [
        {
          operation: "edit",
          name: "编辑"
        },
        {
          operation: "del",
          name: "删除"
        },
        {
          operation: "preview",
          name: "查看全文"
        },
        {
          operation: "default",
          name: "设为新邮件默认签名"
        },
        {
          operation: "defaultReply",
          name: "设为转发/回复默认签名"
        }
      ];
    },
    async getSignList() {
      this.loading = true;
      const res = await mailSettingService.queryMailSignList({ pageNo: 1, pageSize: 1000 });
      this.loading = false;
      this.autoGraphList = res || [];
    }
  }
};
</script>
<style lang="scss">
.auto-graph-card {
  .en-card-big-main {
    .en-card-big-main-title {
      line-height: 15px !important;
    }
    .en-card-big-main-sub-title {
      display: none;
    }
    .en-card-big-main-description {
      white-space: pre-wrap !important;
      div {
        margin-top: 0;
        height: 28px;
        line-height: 14px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
  .en-card-big-menu-list {
    width: 148px !important;
    .en-card-big-menu-item {
      padding-left: 12px !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.auto-graph-manage {
  height: 100%;
  .title {
    color: #333333;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #e8ecf2;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .auto-graph-container {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: calc(100% - 50px);
    overflow: auto;
    align-content: baseline;
    .auto-graph-card {
      width: 49.2% !important;
      margin-bottom: 12px;
      align-items: center;
      /deep/ .en-card-big-main-sub-title {
        margin-top: 14px;
      }
      .default-tag, .default-reply-tag {
        display: inline-block;
        width: 36px;
        height: 38px;
        overflow: hidden;
        background: url("../../assets/default.png") -1px 0 no-repeat;
      }
      .default-reply-tag {
        background: url("../../assets/default.png") -0 -38px no-repeat;
      }
    }
  }
}
</style>

<style lang="scss">
.en-card-big {
  .en-card-big-tag {
    overflow: hidden;
  }
}
</style>
