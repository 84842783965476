<template>
  <el-dialog
    top="10vh"
    :title="dialogTitle"
    custom-class="auto-graph-dialog"
    :visible.sync="dialogVisible"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    width="1100px"
  >
    <div class="auto-graph-form">
      <el-form label-position="left" ref="basicForm" :rules="rules" label-width="70px" :model="formData">
        <el-form-item label="签名标题" prop="title">
          <el-input v-model="formData.title" placeholder="请输入签名标题" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="其它选项" style="margin-bottom: 10px;">
          <div>
            <el-checkbox :true-label="1" :false-label="0" v-model="formData.isDefault">应用于新邮件签名</el-checkbox>
          </div>
          <div>
            <el-checkbox :true-label="1" :false-label="0" v-model="formData.isReplyDefault">应用于转发/回复签名</el-checkbox>
          </div>
        </el-form-item>
      </el-form>
      <mail-editor :editor-height="400" ref="mailEditor"></mail-editor>
    </div>
    <span slot="footer">
      <en-button type="primary" style="font-size: 12px;" :loading="btnLoading" @click="submitHandler" size="medium">确 定</en-button>
    </span>
  </el-dialog>
</template>

<script>
import MailEditor from "../MailSend/MailEditor";

const originData = {
  title: "",
  isDefault: 0,
  isReplyDefault: 0
};
export default {
  components: {
    MailEditor
  },
  name: "CreateAutoGraphDialog",
  props: {
    dialogType: {
      type: String,
      default: "add"
    }
  },
  computed: {
    dialogTitle() {
      if (this.dialogType === "add") {
        return "新建签名";
      }
      return "编辑签名";
    }
  },
  watch: {
  },
  data() {
    return {
      btnLoading: false,
      dialogVisible: false,
      formData: {
        ...originData
      },
      rules: {
        title: [
          { required: true, message: "请输入签名标题", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    submitHandler() {
      this.$refs.basicForm.validate((valid) => {
        const content = this.$refs.mailEditor.getPureEditorData();
        // if (!content) {
        //   this.$message.warning("请输入签名内容");
        //   return;
        // }
        if (!valid) return;
        this.$emit("submitForm", {
          ...this.formData,
          content
        });
        this.btnLoading = true;
      });
    },
    show(data) {
      this.dialogVisible = true;
      if (data) {
        Object.assign(this.formData, data);
        this.$nextTick(() => {
          this.$refs.mailEditor.setContent(data.content);
        });
      } else {
        this.formData = { ...originData };
      }
    },
    hide() {
      this.btnLoading = false;
      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="scss">
.auto-graph-dialog {
  .el-dialog__footer {
    padding-top: 10px !important;
  }
  .el-dialog__body {
    padding: 10px 0 0 0 !important;
  }
}
.auto-graph-form {
  .el-form {
    padding: 0 20px;
  }
  .checkbox-item {
    margin-bottom: 10px;
  }
  .form-title {
    font-size: 12px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .fold-title {
    cursor: pointer;
    .el-icon-arrow-down {
      transition: all .3s;
      color: #3e90fe;
      font-size: 14px;
      margin-left: 4px;
    }
    .rotate-arrow {
      transform: rotate(-90deg);
    }
  }
  .border {
    width: 100%;
    height: 1px;
    background: #e8ecf2;
    margin: -10px 0 20px 0;
  }
  .seat {
    height: 250px;
  }
}
</style>
