var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.autoGraphData.title,
        visible: _vm.dialogVisible,
        "destroy-on-close": true,
        "custom-class": "preview-dialog",
        width: "700px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", {
        staticClass: "w-e-text",
        staticStyle: { "min-height": "200px" },
        domProps: { innerHTML: _vm._s(_vm.autoGraphData.content) },
      }),
      _c("span", { attrs: { slot: "footer" }, slot: "footer" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }