<template>
  <el-dialog
    :title="autoGraphData.title"
    :visible.sync="dialogVisible"
    :destroy-on-close="true"
    custom-class="preview-dialog"
    width="700px"
  >
    <div class="w-e-text" v-html="autoGraphData.content" style="min-height: 200px;"></div>
    <span slot="footer">
    </span>
  </el-dialog>
</template>

<script>

export default {
  name: "PreviewDialog",
  data() {
    return {
      dialogVisible: false,
      autoGraphData: {
        title: "",
        content: ""
      }
    };
  },
  methods: {
    show(data) {
      this.autoGraphData = data;
      this.dialogVisible = true;
    },
    hide() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="scss">
.preview-dialog {
  table {
    border-left: 1px solid #ccc;
    td, th {
      border-bottom: 1px solid #ccc;
      border-right: 1px solid #ccc;
    }
  }
}
</style>
