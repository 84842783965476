/*
 * @Author: your name
 * @Date: 2021-03-15 11:38:33
 * @LastEditTime: 2021-03-17 17:04:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \mailbox\src\api\mailSetting\mailSettingService.js
 */
import BaseService from "../../BaseService";

class MailSettingService extends BaseService {
  constructor(http) {
    super("", http);
  }

  // 查询个人邮箱账户
  getMailAccount(params) {
    return this.http.post("/getMailAccount", params);
  }

  // 添加黑白名单
  addMailMarkAddress(params) {
    return this.http.post("/addMailMarkAddress", params);
  }

  // 删除黑白名单
  deleteMailMarkAddress(params) {
    return this.http.post("/deleteMailMarkAddress", params);
  }

  // 查询黑白名单
  queryMailMarkAddress(params) {
    return this.http.post("/queryMailMarkAddress", params);
  }

  // 收发邮件设置
  saveUserConfig(params) {
    return this.http.post("/saveUserConfig", params);
  }

  // 查询收发邮件设置
  queryUserConfig(params) {
    return this.http.post("/queryUserConfig", params);
  }

  // 批量查询收发邮件设置
  queryUserConfigMultiple(params) {
    return this.http.post("/queryUserConfigMultiple", params);
  }

  // 批量设置邮件设置
  saveUserConfigMultiple(params) {
    return this.http.post("/saveUserConfigMultiple", params);
  }

  // 搜索联系人
  searchContact(params) {
    return this.http.post("searchContact", params);
  }

  // 删除邮件过滤
  deleteMailFilter(params) {
    return this.http.post("deleteMailFilter", params);
  }

  // 保存邮件过滤
  saveMailFilter(params) {
    return this.http.post("saveMailFilter", params);
  }

  // 停用启用邮件过滤
  enableMailFilter(params) {
    return this.http.post("enableMailFilter", params);
  }

  // 执行邮件过滤
  executeMailFilter(params) {
    return this.http.post("executeMailFilter", params);
  }

  // 查询邮件过滤
  queryMailFilter(params) {
    return this.http.post("queryMailFilter", params);
  }

  // 查询账号列表
  queryMailAccountList(params) {
    return this.http.post("queryMailAccountList", params);
  }

  // 新增账号
  addMailAccountByGeneral(params) {
    return this.http.post("addMailAccountByGeneral", params);
  }

  // 修改账号
  updateMailAccountByGeneral(params) {
    return this.http.post("updateMailAccountByGeneral", params);
  }

  // 启用禁用账号
  setMailAccountStatusByGeneral(params) {
    return this.http.post("setMailAccountStatusByGeneral", params);
  }

  // 账号删除
  deleteMailAccountByGeneral(params) {
    return this.http.post("deleteMailAccountByGeneral", params);
  }

  // 调整邮箱顺序
  sortMailAccountStatusByGeneral(params) {
    return this.http.post("sortMailAccountStatusByGeneral", params);
  }

  // 设置查询
  getMailServerSettings(params) {
    return this.http.post("getMailServerSettings", params);
  }

  // 收取邮件接口
  receiveMail(params) {
    return this.http.post("receiveMail", params);
  }

  // 检查发送失败邮件数据
  checkSendErrorMail() {
    return this.http.post("checkSendErrorMail");
  }

  // 查询邮件签名列表
  queryMailSignList() {
    return this.http.post("queryMailSignList");
  }

  // 添加邮件签名
  addMailSign(params) {
    return this.http.post("addMailSign", params);
  }

  // 修改邮件签名
  updateMailSign(params) {
    return this.http.post("updateMailSign", params);
  }

  // 删除邮件签名
  deleteMailSign(params) {
    return this.http.post("deleteMailSign", params);
  }
}

export default MailSettingService;
