var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "auto-graph-manage" },
    [
      _c(
        "div",
        { staticClass: "title" },
        [
          _c("span", [_vm._v("签名设置")]),
          _c(
            "en-button",
            {
              staticStyle: { height: "30px", "font-size": "12px" },
              attrs: { iconSize: "12", icon: "add" },
              on: { click: _vm.addAutoGraph },
            },
            [_vm._v("添加")]
          ),
        ],
        1
      ),
      _c("en-result", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.autoGraphList.length === 0 && !_vm.loading,
            expression: "autoGraphList.length === 0 && !loading",
          },
        ],
        attrs: { type: "NoData" },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "auto-graph-container",
        },
        _vm._l(_vm.autoGraphList, function (card, index) {
          return _c(
            "en-card",
            {
              key: card.id,
              staticClass: "auto-graph-card",
              attrs: {
                type: "big",
                title: card.title,
                "data-info": card,
                "icon-name": "iconqianmingtubiao-youxiang",
                "icon-color": "#d69e79",
                "edit-able": "true",
                "menu-list": _vm.menuList(card, index),
              },
              on: { change: _vm.handlerCallback },
            },
            [
              _c("template", { slot: "description" }, [
                _c("div", { domProps: { innerHTML: _vm._s(card.content) } }),
              ]),
              _c("template", { slot: "tag" }, [
                _c("div", [
                  card.isDefault === 1
                    ? _c("span", { staticClass: "default-tag" })
                    : _vm._e(),
                  card.isReplyDefault === 1
                    ? _c("span", { staticClass: "default-reply-tag" })
                    : _vm._e(),
                ]),
              ]),
            ],
            2
          )
        }),
        1
      ),
      _c("preview-dialog", { ref: "previewDialog" }),
      _c("create-auto-graph-dialog", {
        ref: "autoGraphDialog",
        attrs: { "dialog-type": _vm.dialogType },
        on: { submitForm: _vm.submitForm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }