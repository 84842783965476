export function getOffset(el) {
  return el.offsetParent
    ? {
      top: el.offsetTop + getOffset(el.offsetParent).top,
      left: el.offsetLeft + getOffset(el.offsetParent).left
    }
    : { top: el.offsetTop, left: el.offsetLeft };
}

export function debounce(fn, delay) {
  let timer = null;
  // eslint-disable-next-line func-names
  return function(...args) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
}

export function bytesToSize(bytes) {
  if (!bytes) return "未知内存";
  bytes = Number(bytes);
  if (bytes === 0) return "0 B";
  const k = 1024; // or 1024
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // eslint-disable-next-line no-restricted-properties
  return `${(bytes / Math.pow(k, i)).toPrecision(3) } ${ sizes[i]}`;
}

export function getFileType(fileName) {
  // 后缀获取
  let suffix = "";
  // 获取类型结果
  let result = "";
  try {
    const flieArr = fileName.split(".");
    suffix = flieArr[flieArr.length - 1];
  } catch (err) {
    suffix = "";
  }
  // fileName无后缀返回 false
  if (!suffix) { return false; }
  suffix = suffix.toLocaleLowerCase();
  // 图片格式
  const imglist = ["png", "jpg", "jpeg", "bmp", "gif"];
  // 进行图片匹配
  result = imglist.find((item) => item === suffix);
  if (result) {
    return "img";
  }
  // 匹配txt
  const txtlist = ["txt"];
  result = txtlist.find((item) => item === suffix);
  if (result) {
    return "txt";
  }
  // 匹配 excel
  const excelist = ["xls", "xlsx", "csv"];
  result = excelist.find((item) => item === suffix);
  if (result) {
    return "excel";
  }
  // 匹配 word
  const wordlist = ["doc", "docx"];
  result = wordlist.find((item) => item === suffix);
  if (result) {
    return "word";
  }
  // 匹配 pdf
  const pdflist = ["pdf"];
  result = pdflist.find((item) => item === suffix);
  if (result) {
    return "pdf";
  }
  // 匹配 ppt
  const pptlist = ["ppt", "pptx"];
  result = pptlist.find((item) => item === suffix);
  if (result) {
    return "ppt";
  }
  // 匹配 视频
  const videolist = ["mp4", "m2v", "mkv", "rmvb", "wmv", "avi", "flv", "mov", "m4v"];
  result = videolist.find((item) => item === suffix);
  if (result) {
    return "video";
  }
  // 匹配 音频
  const radiolist = ["mp3", "wav", "wmv"];
  result = radiolist.find((item) => item === suffix);
  if (result) {
    return "radio";
  }
  // 匹配压缩包
  const ziplist = ["zip", "rar"];
  result = ziplist.find((item) => item === suffix);
  if (result) {
   return "zip";
  }
  // 其他 文件类型
  return "other";
}

export function removeRepeatByKey(arr, key = "id") {
  const idMap = {};
  const result = [];
  arr.forEach((item) => {
    if (!idMap[item[key]]) {
      idMap[item[key]] = item;
      result.push(item);
    }
  });
  return result;
}
