var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        top: "10vh",
        title: _vm.dialogTitle,
        "custom-class": "auto-graph-dialog",
        visible: _vm.dialogVisible,
        "destroy-on-close": true,
        "close-on-click-modal": false,
        width: "1100px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "auto-graph-form" },
        [
          _c(
            "el-form",
            {
              ref: "basicForm",
              attrs: {
                "label-position": "left",
                rules: _vm.rules,
                "label-width": "70px",
                model: _vm.formData,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "签名标题", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入签名标题", maxlength: "20" },
                    model: {
                      value: _vm.formData.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "title", $$v)
                      },
                      expression: "formData.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { label: "其它选项" },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { "true-label": 1, "false-label": 0 },
                          model: {
                            value: _vm.formData.isDefault,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "isDefault", $$v)
                            },
                            expression: "formData.isDefault",
                          },
                        },
                        [_vm._v("应用于新邮件签名")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { "true-label": 1, "false-label": 0 },
                          model: {
                            value: _vm.formData.isReplyDefault,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "isReplyDefault", $$v)
                            },
                            expression: "formData.isReplyDefault",
                          },
                        },
                        [_vm._v("应用于转发/回复签名")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c("mail-editor", {
            ref: "mailEditor",
            attrs: { "editor-height": 400 },
          }),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "en-button",
            {
              staticStyle: { "font-size": "12px" },
              attrs: {
                type: "primary",
                loading: _vm.btnLoading,
                size: "medium",
              },
              on: { click: _vm.submitHandler },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }