/*
 * @Author: your name
 * @Date: 2021-03-15 11:38:33
 * @LastEditTime: 2021-03-18 11:46:00
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \mailbox\src\api\mailSetting\index.js
 */
import MailSettingService from "./mailSettingService";

export const mailSettingService = new MailSettingService();
